<template>
  <v-col cols="12" class="pa-0" v-if="isVisible">
    <v-col cols="12">
      <simple-card class="gp-container">
        <template v-slot:title-left>
          <v-col cols="12" class="pb-0 pl-0 gp-text-header text-left">
            Госпошлина <span class="gp-text-sub-header">(комиссия 2.8%, но не менее 10₽)</span>
          </v-col>
        </template>
        <template v-slot:content>
          <table class="gp-table w-full" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th>Номер дела</th>
                <th>Сумма</th>
                <th>Начало ПЗ</th>
                <th>Окончание ПЗ</th>
                <th>Получатель</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in gp"
                :key="item.id"
              >
                <td>{{ number_sp(item) }}</td>
                <td>{{ sum(item) }}</td>
                <td>{{ start(item) }}</td>
                <td>{{ end(item) }}</td>
                <td>{{ management_company(item) }}</td>
                <td><a @click="goToPaymentPage(item)">Оплатить</a></td>
              </tr>
            </tbody>
          </table>
        </template>
      </simple-card>
    </v-col>
  </v-col>
</template>

<script>
import SimpleCard from "@/components/cards/SimpleCard";
import {getFormattedDate} from "@/shared/helpers/date";
import {mapGetters} from "vuex";

export default {
  name: "GP",
  components: { SimpleCard },
  props: {
    isMobile: Boolean,
  },
  computed: {
    ...mapGetters("account", ["gp"]),
    isVisible() {
      return (this.gp?.length || 0) > 0;
    },
  },
  methods: {
    number_sp(item) {
      return item && item['number_sp'];
    },
    sum(item) {
      return item && item['sum'].toFixed(2) + " ₽";
    },
    start(item) {
      return item && item['start'] && getFormattedDate(item['start'], "DD.MM.YYYY");
    },
    end(item) {
      return item && item['end'] && getFormattedDate(item['end'], "DD.MM.YYYY");
    },
    management_company(item) {
      return item && item['management_company']?.['name'];
    },
    goToPaymentPage(item) {
      const query = {};
      query.gp = item.id; // item['management_company']?.['id'];
      query.amount = item.sum.toFixed(2);
      this.$router.push({ name: "payment", query });
    },
  },
};
</script>

<style lang="scss">
.gp-container {
  .gp-text-header {
    //line-height: 48px;
    //color: #f44336;
    font-size: 1.2rem;
  }
  .gp-button {
    background-color: #f44336 !important;
    color: #ffffff !important;
  }
  .v-card__actions {
    display: none !important;
  }
  .gp-text-sub-header {
    //line-height: 48px;
    //font-size: 1rem;
    color: var(--v-secondary-base);
  }
  .gp-table td,
  .gp-table th {
    margin: 0;
    padding: 5px 20px;
    border: 1px solid rgba(94, 86, 105, 0.14);
  }
  .gp-table th {
    border-bottom: 0;
    background-color: rgba(138, 141, 147, 0.082);
  }
  .gp-table tr:not(:last-child) td {
    border-bottom: 0;
  }
  .gp-table th:not(:first-child),
  .gp-table td:not(:first-child) {
    border-left: 0;
  }
  .gp-table th:first-child {
    border-radius: 4px 0 0 0;
  }
  .gp-table th:last-child {
    border-radius: 0 4px 0 0;
  }
  .gp-table td:first-child {
    border-radius: 0 0 0 4px;
  }
  .gp-table td:last-child {
    border-radius: 0 0 4px 0;
  }
  .gp-table th:first-child,
  .gp-table th:nth-child(5) {
    min-width: 150px;
  }
  .gp-table th:nth-child(2) {
    min-width: 100px;
  }
  .gp-table th:nth-child(3),
  .gp-table th:nth-child(4) {
    min-width: 120px;
  }
}
</style>
