<template>
  <v-col cols="12" class="pa-0" v-if="isVisible">
    <v-col cols="12" class="mb-0 pb-0">
      <v-expansion-panels v-for="item in items" :key="item.id" class="debt-container mb-5">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="12" class="pb-0 debt-text-header text-left">
                {{ item.link }}
              </v-col>
              <v-col v-if="item.payTinkoff" cols="12" class="pt-0 text-left">
                (комиссия 0.8%, но не менее 3₽)
              </v-col>
              <v-col v-if="item.management_company !== ''" cols="12" class="pt-0 text-left">
                {{ item.management_company }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <table class="debt-table w-full pb-4" cellspacing="0" cellpadding="0" v-if="item.schedule.length > 0">
              <thead>
              <tr>
                <th>Дата оплаты</th>
                <th>Сумма</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="itemSchedule in item.schedule" :key="itemSchedule.date">
                <td>{{ itemSchedule.date }}</td>
                <td>{{ itemSchedule.schedule_plan }}</td>
              </tr>
              </tbody>
            </table>
            <table>
              <tbody>
              <tr>
                <td class="pr-5">
                  <div class="text-left pa-0">Остаток долга</div>
                  <div class="text-left pa-0 text-base font-weight-bold">
                    {{ sum(item) }}
                  </div>
                </td>
                <td class="text-left pa-0 d-inline-block">
                  <v-btn
                    :block="isMobile"
                    :small="isMobile"
                    class="full-button debt-button"
                    color="primary"
                    @click="goToPaymentPage(item)"
                  >
                    Оплатить задолженность
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-col>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "Debt",
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    debt() {
      this.formatItems();
    },
  },
  computed: {
    ...mapGetters("account", ["debt"]),
    isVisible() {
      return (this.debt?.length || 0) > 0;
    },
  },
  mounted() {
    this.formatItems();
  },
  methods: {
    formatItems() {
      const startOfMonth = moment().startOf('month');
      const endOfMonth = moment().endOf('month');
      this.items = this.debt.map((item) => {
        let result = {
          id: item.id,
          management_company: item.management_company.name,
          sum: item.sum,
          link: item.link,
          payTinkoff: item.payTinkoff,
          debt: 0,
        };

        try {
          let itemSchedule = JSON.parse(item.schedule);
          const schedule = itemSchedule
            .sort((a, b) => Date.parse(a["date"]) - Date.parse(b["date"]))
            .filter((item) => item.schedule_plan && item.schedule_plan !== "" && item.date !== "")
            .map((item) => {
              const schedule_plan = parseFloat(
                (item.schedule_plan || "").replace(" ", "").replace(",", ".")
              );
              const payed = parseFloat(
                (item.payed || "0").replace(" ", "").replace(",", ".")
              );
              // запишем сумму
              const currentDay = moment(item.date, 'DD.MM.YYYY');
              if (currentDay.isAfter(startOfMonth) && currentDay.isBefore(endOfMonth)) {
                result['debt'] = schedule_plan - payed;
                if (result['debt'] < 0) {
                  result['debt'] = 0;
                }
              }
              return {
                date: item.date,
                schedule_plan: schedule_plan.toFixed(2),
              };
            });
          return {...result, ...{ schedule }};
        } catch (e) {
          console.log(e);
        }
      });
    },
    sum(item) {
      return item && item['sum'].toFixed(2) + " ₽";
    },
    goToPaymentPage(item) {
      const query = {};
      query.debt = item['id'];
      query.amount = item['debt'].toFixed(2);
      this.$router.push({ name: "payment", query });
    },
  },
};
</script>

<style lang="scss">
.debt-container {
  .debt-text-header {
    line-height: 48px;
    //color: #f44336;
    font-size: 1.2rem;
  }
  //.debt-button {
  //  background-color: #f44336 !important;
  //  color: #ffffff !important;
  //}
  .v-card__actions {
    display: none !important;
  }
  .debt-table td,
  .debt-table th {
    margin: 0;
    padding: 5px 20px;
    border: 1px solid rgba(94, 86, 105, 0.14);
  }
  .debt-table th {
    border-bottom: 0;
    background-color: rgba(138, 141, 147, 0.082);
  }
  .debt-table tr:not(:last-child) td {
    border-bottom: 0;
  }
  .debt-table th:not(:first-child),
  .debt-table td:not(:first-child) {
    border-left: 0;
  }
  .debt-table th:first-child {
    border-radius: 4px 0 0 0;
  }
  .debt-table th:last-child {
    border-radius: 0 4px 0 0;
  }
  .debt-table td:first-child {
    border-radius: 0 0 0 4px;
  }
  .debt-table td:last-child {
    border-radius: 0 0 4px 0;
  }
  .debt-table th:first-child {
    min-width: 100px;
  }
  .debt-table th:nth-child(2) {
    min-width: 100px;
  }
}
</style>
